import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

import { UserSessionService } from '../services/usersession.service';

// get access to globals
declare let $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, AfterViewInit, OnDestroy {

  cartTitle = 'SHOPPING CART';

  constructor(
    public session: UserSessionService,
  ) {
  }

  // public doReport(e) {
  // 	e.stopPropagation();
  // 	console.log("doReport", e);
  // }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('app-cart').on('click', (e) => {
      // prevent ClickAway
      e.stopPropagation();
    });
  }

  ngOnDestroy() {
  }
}
