import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface SearchInstrumentsDialogData {
  searchterms: string;
  cancel: boolean;
}

@Component({
  selector: 'app-search-instruments',
  templateUrl: './search-instruments.component.html',
  styleUrls: ['./search-instruments.component.css']
})
export class SearchInstrumentsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SearchInstrumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SearchInstrumentsDialogData

  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.data.cancel = true;
    this.dialogRef.close(this.data);
  }

}
