import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog
  ) { }

  public confirm(msg: any): Observable<ConfirmDialogData> {
    msg.has_cancel = true;
    return this.alert(msg);
  }

  public alert(msg: any): Observable<ConfirmDialogData> {
    msg.has_ok = true;
    return this.dialog.open(ConfirmDialogComponent, {
      data: <ConfirmDialogData>msg	// sufficient overlap for this conversion to work?
    })
      .afterClosed();
  }

  // see https://stackoverflow.com/questions/57652/how-do-i-get-javascript-to-open-a-popup-window-on-the-current-monitor
  public openPopupWindow(url: string, title: string, w: number, h: number): Window | null {
    const dualScreenLeft = typeof window.screenLeft !== 'undefined' ? window.screenLeft : (<any>screen).left;
    const dualScreenTop = typeof window.screenTop !== 'undefined' ? window.screenTop : (<any>screen).top;

    const width = window.innerWidth ? window.innerWidth :
      document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight :
      document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;
    const newWindow =
      window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus && newWindow) {
      newWindow.focus();
    }
    return newWindow;
  }

  safeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getDoc(instrument: any, state_tag: string): any {
    if (instrument.state_tag === state_tag) {
      return instrument;
    }
    if (instrument.children && instrument.children.length > 0) {
      for (const cinstrument of instrument.children) {
        if (cinstrument.state_tag === state_tag) {
          return cinstrument;
        }
      }
    }
    return null;
  }

  hasDoc(instrument: any, state_tag: string): boolean {
    return this.getDoc(instrument, state_tag) !== null;
  }

}
