import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

console.log('domain: ' + window.location.hostname);
if (window.location.hostname === 'localhost') {
  environment.apiUrl = '//api-civicatlas-com';
  environment.isLocal = true;
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
