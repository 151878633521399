import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  Component, Injectable, OnInit, AfterViewInit, Input,
  OnDestroy, ViewChild
} from '@angular/core';

import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatCheckboxChange } from '@angular/material';

import { BehaviorSubject } from 'rxjs';

import { UserSessionService } from '../services/usersession.service';
import { MessageBusService } from '../services/messagebus.service';
import { UtilService } from '../services/util.service';

import { InstrumentPickedMessage } from '../messagebus-messages/instrumentpicked-message.class';

// get access to globals
declare let $: any;

/**
 * Node for item
 */
export class TreeItemNode {
  children: TreeItemNode[];
  item: string;
  body: any[];
  data: any;
}

/** Flat item node with expandable and level information */
class TreeItemFlatNode {
  item: string;
  body: any[];
  level: number;
  expandable: boolean;
  data: any;
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.css'],
})
export class CategoryTreeComponent implements OnInit, AfterViewInit {
  @Input() database: any;
  @Input() allowAdd = false;
  @Input() isMyParcel = true;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TreeItemFlatNode>;

  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeItemFlatNode>(true /* multiple */);
  private expandAllState = false;
  private selectAllState = false;

  constructor(
    public util: UtilService,
    public session: UserSessionService,
    private mb: MessageBusService,
  ) {
  }

  ngOnInit() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngAfterViewInit() {
    // this.treeControl.expandAll();
  }

  getLevel = (node: TreeItemFlatNode) => node.level;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.item === '';

  /**
	 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
	 */
  transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TreeItemFlatNode();
    flatNode.item = node.item;
    flatNode.body = node.body;
    flatNode.data = node.data;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Select/deselect the item selection. Select/deselect all the descendants node */
  treeItemSelectionSelect(node: TreeItemFlatNode, select: boolean): void {
    if (select) {
      this.checklistSelection.select(node);
    } else {
      this.checklistSelection.deselect(node);
    }

    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );

    this.checkAllParentsSelection(node);
    const checked: boolean = this.checklistSelection.isSelected(node);
    this.database.itemToggle(node, descendants, checked);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  treeItemSelectionToggle(node: TreeItemFlatNode): void {
    this.checklistSelection.toggle(node);

    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );

    this.checkAllParentsSelection(node);
    const checked: boolean = this.checklistSelection.isSelected(node);
    this.database.itemToggle(node, descendants, checked);
  }

  // /** Toggle a leaf item selection. Check all the parents to see if they changed */
  // treeLeafItemSelectionToggle(node: TreeItemFlatNode): void {
  // 	this.checklistSelection.toggle(node);
  // 	this.checkAllParentsSelection(node);
  // 	let checked: boolean = this.checklistSelection.isSelected(node);
  // 	this.database.leafItemToggle(node, checked);
  // }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeItemFlatNode): void {
    let parent: TreeItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeItemFlatNode): TreeItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(e: Event, node: TreeItemFlatNode) {
    e.stopPropagation();
    const parentNode = this.flatNodeMap.get(node);
    this.database.insertItem(parentNode, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(e: Event, node: TreeItemFlatNode, itemValue: string) {
    e.stopPropagation();
    const nestedNode = this.flatNodeMap.get(node);
    this.database.updateItem(nestedNode, itemValue);
  }

  toggleAll(e): void {
    e.stopPropagation();
    // console.log("toggleAll", e);
    this.flatNodeMap.forEach((value, key) => {
      if (key.level === 0) {
        // console.log(key, value);
        this.treeItemSelectionToggle(key);
      }
    });
  }

  selectAll(e, on?: boolean): void {
    if (e) { e.stopPropagation(); }
    if (typeof on !== 'undefined') { this.selectAllState = !on; }
    this.selectAllState = !this.selectAllState;
    // console.log("selectAll", e, this.selectAllState);
    this.flatNodeMap.forEach((value, key) => {
      this.treeItemSelectionSelect(key, this.selectAllState);
    });
  }

  expandAll(e, on?): void {
    if (e) { e.stopPropagation(); }
    if (typeof on !== 'undefined') { this.expandAllState = !on; }
    this.expandAllState = !this.expandAllState;
    // console.log("expandAll", e, this.expandAllState);
    this.flatNodeMap.forEach((value, key) => {
      if (key.expandable) {
        this.expandAllState ? this.treeControl.expand(key) : this.treeControl.collapse(key);
      }
    });
  }

  hasSample(instrument: any): boolean {
    return null !== this.util.getDoc(instrument, 'SAMPLE');
  }

  // enterInstrument(e: any, instrument: any): void {
  //   // console.log('enter', instrument);
  //   const element = $(e.target).parent();
  //   $(element).css({position: 'relative'});
  //   $(element).addClass('hilight');
  //   $(element).append('<div class="hover-box">City of Toronto Fire Services - Notice of Violation</div>');
  //   $('.hover-box').css({top: 0, left: 0});
  //   // const h = $(element).height();
  //   // const offset = $(element).offset();
  //   // $('.hover-box').css({top: -20, left: e.x - offset.left});
  //   $('.hover-box').css({top: -30, left: 25});
  // }

  // leaveInstrument(e: any, instrument: any): void {
  //   // console.log('leave', instrument);
  //   const element = $(e.target).parent();
  //   $(element).removeClass('hilight');
  //   // $('.hover-box').remove();
  // }

  tooltip(instrument: any): string {
    return instrument.hint_tag;
    // if (instrument.filename.includes('Fire Services Notice')) {
    //   return 'City of Toronto Fire Services - Notice of Violation';
    // }
    // return null;
  }

  doInstrument(e, instrument) {
    if (e) { e.stopPropagation(); }
    // console.log("doInstrument", instrument);
    this.mb.send(new InstrumentPickedMessage(instrument, 'MAIN'));
  }

  doInstrumentSample(e, instrument) {
    if (e) { e.stopPropagation(); }
    // console.log("doInstrument", instrument);
    this.mb.send(new InstrumentPickedMessage(instrument, 'SAMPLE'));
  }

}
