import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Router } from '@angular/router';

import { UserSessionService } from '../services/usersession.service';

@Component({
  selector: 'app-page-base',
  templateUrl: './page-base.component.html',
  styleUrls: ['./page-base.component.css']
})
export class PageBaseComponent implements OnInit, AfterViewInit {

  constructor(
    protected session: UserSessionService,
    protected router: Router,
  ) {
  }

  ngOnInit() {
    console.log('PageBaseComponent.ngOnInit() called');
  }

  ngAfterViewInit() {
  }
}
