import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatGridListModule, MatProgressBarModule } from '@angular/material';
import { MatSnackBarModule, MatCardModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule, /*MatBottomSheetModule*/ } from '@angular/material';
import { MatDialogModule, MatFormFieldModule, MatInputModule, MatIconModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent, SafePipe } from './app.component';
import { HttpRequestInterceptor } from './http-request-interceptor';
import { LFMapElementComponent } from './lfmap-element/lfmap-element.component';
import { OLMapElementComponent/*, BottomSheetFeatureInfo*/ } from './olmap-element/olmap-element.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomePageComponent } from './home-page/home-page.component';
import { CartComponent } from './cart/cart.component';
import { MenuComponent } from './menu/menu.component';
import { InstrumentsComponent } from './instruments/instruments.component';
import { LoginComponent } from './login/login.component';
import { ParcelPickerComponent } from './parcel-picker/parcel-picker.component';
import { PortfolioComponent } from './portfolio/portfolio.component';

import { OverlayContainer } from '@angular/cdk/overlay';
import { ParcelPageComponent } from './parcel-page/parcel-page.component';
import { InstrumentCategoryComponent } from './instrument-category/instrument-category.component';

import { MatTreeModule } from '@angular/material/tree';
import { CategoryTreeComponent } from './category-tree/category-tree.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

import { PathResolveService } from './services/path-resolve.service';
import { InstrumentPageComponent } from './instrument-page/instrument-page.component';
import { PageBaseComponent } from './page-base/page-base.component';

// import { CookieService } from 'ngx-cookie-service';

import { paths } from './app-paths';
import { MapInstrumentPanelComponent } from './map-instrument-panel/map-instrument-panel.component';
import { SearchInstrumentsComponent } from './search-instruments/search-instruments.component';

const appRoutes: Routes = [
  {
    path: paths.home,
    component: HomePageComponent
  }, {
    path: paths.home + '/:uid',
    component: HomePageComponent
  }, {
    path: paths.parcel + '/:id',
    component: ParcelPageComponent
  }, {
    path: paths.instrument + '/:id/:iid/:uid',
    component: InstrumentPageComponent
    // }, {
    // 	path: paths.instrument + '/:id',
    // 	component: InstrumentPageComponent
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: '/' + paths.home
  }, {
    path: '**',
    component: PageNotFoundComponent,
    resolve: {
      path: PathResolveService
    }
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LFMapElementComponent,
    OLMapElementComponent,
    PageNotFoundComponent,
    HomePageComponent,
    // BottomSheetFeatureInfo
    CartComponent,
    MenuComponent,
    InstrumentsComponent,
    LoginComponent,
    ParcelPickerComponent,
    PortfolioComponent,
    ParcelPageComponent,
    InstrumentCategoryComponent,
    CategoryTreeComponent,
    ConfirmDialogComponent,
    InstrumentPageComponent,
    SafePipe,
    PageBaseComponent,
    MapInstrumentPanelComponent,
    SearchInstrumentsComponent
  ],
  entryComponents: [
    // BottomSheetFeatureInfo
    LoginComponent,
    SearchInstrumentsComponent,
    ConfirmDialogComponent,
    ParcelPickerComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule, MatCheckboxModule, MatSnackBarModule, MatCardModule, MatGridListModule, MatProgressBarModule,
    MatMenuModule, MatExpansionModule,
    // MatBottomSheetModule,
    NgbTooltipModule,
    MatDialogModule, MatFormFieldModule, MatInputModule, MatIconModule,
    MatTreeModule,
    FormsModule,
    FlexLayoutModule,
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: true,
        // enableTracing: true	 // for debugging
      }

    )
  ],
  providers: [
    // Http Interceptor(s) -  adds with Client Credentials
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }],
    // CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('alternate-theme');
  }
}
