import { Component, OnInit, AfterViewInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { InstrumentCategoryComponent } from '../instrument-category/instrument-category.component';
import { OLMapElementComponent } from '../olmap-element/olmap-element.component';

import { MessageBusService } from '../services/messagebus.service';
import { UserSessionService } from '../services/usersession.service';
import { ApiServerService } from '../services/apiserver.service';
import { UtilService } from '../services/util.service';

import { InstrumentPickedMessage } from '../messagebus-messages/instrumentpicked-message.class';

// get access to globals
declare let $: any;

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.css']
})
export class InstrumentsComponent implements OnInit, AfterViewInit, OnDestroy {

  // snapCharge = 10.00;

  @ViewChild(InstrumentCategoryComponent, { static: false }) instrumentCategoryComponent: InstrumentCategoryComponent;
  @ViewChild(OLMapElementComponent, { static: false }) olmap: OLMapElementComponent;

  get totalSelected(): number {
    const instruments: object[] = [];
    return this.appendSelectedInstruments(instruments, this.session.instruments);
  }

  get totalAvailable(): number {
    // let instruments: object[] = [];
    return this.availableInstrumentsTotal(this.session.instruments);
  }

  get countPurchased(): number {
    return this.purchasedInstrumentsCount(this.session.instruments);
  }

  get countAvailable(): number {
    return this.availableInstrumentsCount(this.session.instruments);
  }

  // private reportWindow: any;
  report_content = '';

  constructor(
    private router: Router,
    public session: UserSessionService,
    private api: ApiServerService,
    private mb: MessageBusService,
    private util: UtilService,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.closePopup();
  }

  private closePopup(): void {
    // if (this.reportWindow) {
    // 	this.reportWindow.close();
    // 	this.reportWindow = null;
    // }
  }

  // public panTo(parcel): void {
  // 	this.olmap.panTo(parcel);
  // }

  private appendSelectedInstruments(instruments: object[], categories: any): number {
    let total = 0;
    categories.forEach((category) => {
      if (category.checked) {
        category.instruments.forEach((instrument) => {
          if (instrument.user_id !== this.session.uid) {
            instruments.push(instrument);
            total += instrument.price;
          }
        });
      }
      total += this.appendSelectedInstruments(instruments, category.children);
    });
    return total;
  }

  private availableInstrumentsTotal(categories: any): number {
    let total = 0;
    categories.forEach((category) => {
      category.instruments.forEach((instrument) => {
        if (instrument.user_id !== this.session.uid) {
          total += instrument.price;
        }
      });
      total += this.availableInstrumentsTotal(category.children);
    });
    return total;
  }

  private purchasedInstrumentsCount(categories: any): number {
    let count = 0;
    categories.forEach((category) => {
      category.instruments.forEach((instrument) => {
        // TODO: fix this (and other places too) where === fails because one is INT and other is STRING
        if (instrument.user_id == this.session.uid) {
          count += 1;
        }
      });
      count += this.purchasedInstrumentsCount(category.children);
    });
    return count;
  }

  private availableInstrumentsCount(categories: any): number {
    return (categories.length) ? categories[0].subtreeInstrumentCount : 0;
  }

  public doSnapshot(e: any) {
    e.stopPropagation();
    console.log('doSnapshot', e);

    this.util.confirm({
      message: 'Do you agree to a charge of $' + this.session.numberFormat(this.session.snapshotPrice, 2) + '?'
    }).subscribe(result => {
      if (result && !result.cancel) {
        this.api.getParcels({
          // uid: this.session.uid,
          id: this.session.parcelid,
          addParcelToUID: true
        }).subscribe(response => {
          console.log('addParcelToUID', response);
          if (response.status === 'OK') {
            this.session.addCartItem('snapshop charge', this.session.snapshotPrice);
            this.session.setParcels(response.parcel);
            this.session.setParcelIsMine(true);
            this.closePopup();
          } else {
            this.util.alert({ message: response.messages.join() });
          }
        });
      }
    });
  }

  public doPurchaseSelected(e: any) {
    e.stopPropagation();
    console.log('doPurchaseSelected', e);

    const instruments: object[] = [];
    const total: number = this.appendSelectedInstruments(instruments, this.session.instruments);
    // console.log('instruments', instruments);

    if (instruments.length) {
      this.util.confirm({
        message: 'Do you agree to a charge of $' + this.session.numberFormat(total, 2) + '?'
      }).subscribe(result => {
        if (result && !result.cancel) {
          this.api.getParcels({
            // uid: this.session.uid,
            id: this.session.parcelid,
            instruments: instruments,
            addInstrumentsToUID: true
          }).subscribe(response => {
            console.log('addInstrumentsToUID', response);
            if (response.status === 'OK') {
              this.session.addCartItem('instrument purchases', response.result.docsCost);
              this.session.setParcels(response.parcel);
              this.closePopup();
            } else {
              this.util.alert({ message: response.messages.join() });
            }
          });
        }
      });
    } else {
      this.util.alert({ message: 'no new instruments selected' });
    }
  }

  doBuyAll(e: any): void {
    e.stopPropagation();
    this.instrumentCategoryComponent.selectAll(true);
    this.doPurchaseSelected(e);
  }

  doImagePopup(e: any, url: string): void {
    e.stopPropagation();
    this.util.alert({title: '', url: url });
  }

  doNotImplemented(e: any): void {
    e.stopPropagation();
    this.util.alert({ message: 'Not yet implemented!', title: 'INFO' });
  }

  public doReport(e: any) {
    e.stopPropagation();
    // console.log('doReport', e);

    // if (this.reportWindow && !this.reportWindow.closed) {
    // 	this.reportWindow.focus();
    // } else {
    // 	this.api.getReport({
    // 		uid: this.session.uid,
    // 		id: this.session.parcelid,
    // 	}).subscribe(response => {
    // 		console.log('getReport', response);
    // 		if (response.status == 'OK') {
    // 			this.reportWindow = this.util.openPopupWindow(response.report_url, 'report_tab', 800, 600);
    // 		} else {
    // 			this.util.alert({ message: response.messages.join() });
    // 		}
    // 	});
    // }

    this.router.navigate(['/instrument', this.session.parcelid, 'all', this.session.uid]);
  }

  public doPublish(e: any) {
    e.stopPropagation();
    console.log('publish', this.session.parcels);
    const parcel_id = this.session.parcels.features[0].id;
    console.log('publish', parcel_id);

    const steps = [];
    switch (parcel_id) {
      case 457174: {  // 507 King in CA database
        steps.push({
          object: 'feature',
          id: 208188, // 507 King in CMS
          setting: 'public',
          parcel_id: parcel_id
        });
        steps.push({
          object: 'feature',
          id: 208187,
          setting: 'draft',
          parcel_id: parcel_id
        });
        break;
      }
      case 429119: { // 46 Laing in CA database
        steps.push({
          object: 'feature',
          id: 208187, // 46 Laing in CMS
          setting: 'public',
          parcel_id: parcel_id
        });
        break;
      }
    }
    this.api.postToCMS({
      action: 'setvisibility',
      steps: steps
    }).subscribe(response => {
      console.log('postToCMS', response);
      if (response.status === 'OK') {
        console.log(response);
        this.util.alert({ message: 'Parcel Feature has been published!', title: 'INFO' });
      } else {
        this.util.alert({ message: response.error });
      }
    });
  }

}
