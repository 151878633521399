import { Component, Inject, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

// import { MatDialog } from '@angular/material';

import { ApiServerService } from './services/apiserver.service';
// import { LoginComponent, LoginDialogData } from './login/login.component';
// import { ConfirmDialogComponent, ConfirmDialogData } from './confirm-dialog/confirm-dialog.component';

import { MessageBusService } from './services/messagebus.service';
import { UserSessionService } from './services/usersession.service';

import { Observable, Subscription } from 'rxjs';
import { ClickAwayMessage } from './messagebus-messages/clickaway-message.class';
// import { ConfirmMessage } from './messagebus-messages/confirm-message.class';

// get access to globals
declare let $: any;

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('slideInOut', [
      state('show', style({
        transform: 'translateX(0)'
      })),
      state('hide', style({
        transform: 'translateX(-100%)'
      })),
      transition('* => *', animate('300ms ease-in-out')),
    ]),
  ]
})
export class AppComponent implements AfterViewInit {

  menuState = 'hide';

  activeComponentName: string;

  private subscription: Subscription;

  constructor(
    private mb: MessageBusService,
    public api: ApiServerService,
    private router: Router,
    public session: UserSessionService,
    // public dialog: MatDialog,
    public location: Location
  ) {
  }

  public toggleMenu(e): void {
    e.stopPropagation();
    console.log('toggleMenu', e);
    this.menuState = (this.menuState === 'show' ? 'hide' : 'show');
    // console.log("toggleMenu", this.menuState);
  }

  ngAfterViewInit() {
    this.subscription = this.mb.recv([
      'ClickAwayMessage',
    ]).subscribe((message: ClickAwayMessage) => {
      // this.confirm(message)
      this.menuState = 'hide';
    });

    $('app-root').on('click', (e) => {
      console.log('start ClickAway', e);
      this.mb.send(new ClickAwayMessage());
      this.menuState = 'hide';
    });

    console.log('router.getCurrentNavigation()', this.router.getCurrentNavigation());
  }

  onActivate(e: any): void {
    console.log('onActivate()', e.route.component.name);
    this.activeComponentName = e.route.component.name;
  }

}
