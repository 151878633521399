import { Injectable } from '@angular/core';

import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
// import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';

/** Inject With Credentials into the request */
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    // private cookieService: CookieService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // console.log("interceptor: " + req.url);
    req = req.clone();
    req.body.token = sessionStorage.getItem('token');
    req.body.isLocal = environment.isLocal;

    return next.handle(req).pipe(
      tap(resp => {
        console.log('interceptor', resp);
        if (resp instanceof HttpResponse) {
          // if(resp.body.token)	{
          // 	sessionStorage.setItem('token', resp.body.token);
          // }
          // console.log('cookie',this.cookieService.get('civicatlas'));
          // console.log('cookie-all', this.cookieService.getAll());
        }
      }));
  }
}
