import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { MessageBusService } from '../services/messagebus.service';
import { UserSessionService } from '../services/usersession.service';
import { OLMapElementComponent } from '../olmap-element/olmap-element.component';
import { Observable, Subscription } from 'rxjs';
import { InstrumentPickedMessage } from '../messagebus-messages/instrumentpicked-message.class';
import { ApiServerService } from '../services/apiserver.service';
import { UtilService } from '../services/util.service';
// import { environment } from '../../environments/environment';

// get access to globals
declare let $: any;

@Component({
  selector: 'app-map-instrument-panel',
  templateUrl: './map-instrument-panel.component.html',
  styleUrls: ['./map-instrument-panel.component.css']
})
export class MapInstrumentPanelComponent implements OnInit, AfterViewInit, OnDestroy {

  layers: any[] = [];
  instruments: any[] = [];
  last_instrument: any = null;

  @ViewChild(OLMapElementComponent, { static: false }) olmap: OLMapElementComponent;
  private subscription: Subscription;

  get showmap(): boolean {
    return (this.instruments.length === 0) || (this.layers.length > 0);
  }

  get parcels(): any {
    return this.session.parcels;
  }

  constructor(
    public session: UserSessionService,
    private mb: MessageBusService,
    public api: ApiServerService,
    private util: UtilService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    this.subscription = this.mb.recv([
      'InstrumentPickedMessage',
    ]).subscribe((message: InstrumentPickedMessage) => {
      console.log('received', message);
      this.instrumentPicked(message.instrument, message.state_tag);
    });

    this.olmap.addParcels(this.session.parcels, 'EPSG:4326');
  }

  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
  }

  closeInstrument(e?: any) {
    if (e) { e.stopPropagation(); }
    this.instruments = [];
    this.last_instrument = null;
    this.layers = [];
    this.olmap.clearLayers();
  }

  private instrumentPicked(instrument: any, state_tag?: any) {
    console.log('instrumentPicked', instrument);
    instrument = this.util.getDoc(instrument, state_tag);

    // const old = this.instruments.length > 0 ? this.instruments[0] : null;
    if (this.last_instrument && this.last_instrument.id === instrument.id) {
      this.closeInstrument();
    } else if (instrument) {
      this.last_instrument = instrument;
      this.instruments = [];
      this.instruments.push(instrument);

      this.api.getInstrument({
        uid: this.session.uid,
        id: this.session.parcelid,
        iid: instrument.id,
        layers: true
      }).subscribe(response => {
        console.log('instrumentPickedChild-response', response);
        if (response.status === 'OK') {
          this.api.setIsLoading(true);
          setTimeout(() => {
            // scroll page to top to see selected document
            const h = $('nav').innerHeight();
            const offset = $('.map-instrument-container').offset();
            document.documentElement.scrollTop = offset.top - h; // subtract off page offset
          }, 0);
          setTimeout(() => {
            if (response.instruments) {

              response.instruments.forEach((instrumentt: any) => {
                instrumentt.children.forEach((child: any) => {
                  this.olmap.addLayers(child, 'EPSG:4326');
                });
              });
            }
            this.api.setIsLoading(false);
          }, 0);
        } else {
          console.log('instrumentPickedChild-response-error', response);
          this.util.alert({ message: response.messages.join() });
        }
      });
    }
  }

}
