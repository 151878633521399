import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface ConfirmDialogData {
  title: string;
  message: string;
  url: string;
  cancel: boolean;
  has_cancel: boolean;
  has_ok: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit, AfterViewInit {

  has_cancel = true;
  has_ok = true;
  visible = true;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) { }

  ngOnInit() {
    this.has_cancel = this.data.has_cancel ? true : false;
    this.has_ok = this.data.has_ok ? true : false;
    if (typeof this.data.title === 'undefined') { this.data.title = this.has_cancel ? 'CONFIRM' : 'ALERT'; }
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.visible = true;
    // }, 100);
  }

  image_loaded(el: any): void {
    console.log('image loaded',el);
    // this.visible = true;
  }

  onNoClick(): void {
    this.data.cancel = true;
    this.dialogRef.close(this.data);
  }
}
