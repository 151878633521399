import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserSessionService } from '../services/usersession.service';
import { ApiServerService } from '../services/apiserver.service';

import { Observable, Subscription } from 'rxjs';
import { MessageBusService } from '../services/messagebus.service';
import { ClickAwayMessage } from '../messagebus-messages/clickaway-message.class';
import { UtilService } from '../services/util.service';

// get access to globals
declare let $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {

  constructor(
    private session: UserSessionService,
    private api: ApiServerService,
    private router: Router,
    private messageBusService: MessageBusService,
    private util: UtilService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('app-menu').on('click', (e) => {
      // e.stopPropagation();
    });
  }

  resetDemo(e): void {
    e.stopPropagation();
    console.log('resetDemo');
    this.api.resetDemo({
    }).subscribe(payload => {
      this.api.getSession({ 'logout': {} })
        .subscribe(_payload => {
          this.session.doLogout();
          this.router.navigate(['/home']);
          this.messageBusService.send(new ClickAwayMessage());
        });
    });
    this.api.postToCMS({
      action: 'resetdemo'
    }).subscribe(response => {
      console.log('postToCMS', response);
      if (response.status === 'OK') {
        console.log(response);
      } else {
        this.util.alert({ message: response.messages.join() });
      }
    });
  }

  toggleCart(e): void {
    e.stopPropagation();
    this.session.toggleCart();
    this.messageBusService.send(new ClickAwayMessage());
  }

  doLogout(e): void {
    e.stopPropagation();
    this.session.doLogout(e);
    this.messageBusService.send(new ClickAwayMessage());
  }
}
