import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ApiServerService } from '../services/apiserver.service';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
// import { environment } from '../../environments/environment';

// import { Location } from '@angular/common';

import { UserSessionService } from '../services/usersession.service';
import { UtilService } from '../services/util.service';
import { SearchInstrumentsComponent, SearchInstrumentsDialogData } from '../search-instruments/search-instruments.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-instrument-page',
  templateUrl: './instrument-page.component.html',
  styleUrls: ['./instrument-page.component.css']
})
export class InstrumentPageComponent implements OnInit, AfterViewInit {
  isLoaded = false;

  parcel_id: number;
  instruments: any[] = [];

  progressRunning = false;
  // progressValue = 50;
  // progressBufferValue = 0;

  searchterms = '';

  constructor(
    public session: UserSessionService,
    private route: ActivatedRoute,
    private router: Router,
    public api: ApiServerService,
    private util: UtilService,
    public dialog: MatDialog,
    // public location: Location,
  ) { }

  ngOnInit() {
    this.isLoaded = false;
    if (!this.session.isLoggedIn) {
      this.route.params.subscribe(params => {
        console.log(params);
        if (params.uid) {
          this.session.fake_login = true;
          this.session.uid = params.uid;
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.session.isLoggedIn) {
      this.session.fake_login = false;
      this.route.params.subscribe(params => {
        console.log('InstrumentPageComponent', params);
        this.parcel_id = params.id;
        if (!this.session.parcelid) {
          this.api.getParcels({
            id: this.parcel_id,
            parcelFromID: true,
            uid: this.session.uid,
            fake_login: true
          })
            .subscribe(response => {
              console.log('ParcelPageComponent-parcel-response', response);
              if (response.status = 'OK') {
                this.session.setParcels(response.parcel);
                this.isLoaded = true;
              } else {
                console.log('ParcelPageComponent-parcel-response-error', response);
                this.util.alert({ message: response.messages.join() });
              }
            });
        }
        this.api.getInstrument({
          uid: this.session.uid,
          id: this.parcel_id,
          iid: params.iid,
          fake_login: true
        }).subscribe(response => {
          console.log('InstrumentPageComponent-instrument-response', response);
          if (response.status === 'OK') {
            this.instruments = [];
            response.instruments.forEach((instrument) => {

              // temp for testing
              instrument.ad_images = [];
              if (instrument.category_name === 'Fire') {
                instrument.ad_images = [
                  { logo: 'assets/fire-service-logo-01.png', url: 'https://epicfireprotection.com/' },
                  { logo: 'assets/fire-service-logo-02.png', url: 'https://h2ofire.ca/' },
                  { logo: 'assets/fire-service-logo-03.jpg', url: 'https://www.firetronics.ca/' },
                ];
              }

              this.instruments.push({
                has_been_selected: false,
                version: 0,
                item: instrument
              });
            });
            this.isLoaded = true;
          } else {
            console.log('InstrumentPageComponent-instrument-response-error', response);
            this.util.alert({ message: response.messages.join() });
          }
        });
      });
    } else {
      this.router.navigate(['/home']);
    }

  }

  onOpened(instrument: any): void {
    instrument.has_been_selected = true;
  }

  onClosed(instrument: any): void {
  }

  // private getChildDoc(instrument: any, state_tag: string): any {
  //   if (instrument.item.children && instrument.item.children.length > 0) {
  //     for (const cinstrument of instrument.item.children) {
  //       if (cinstrument.state_tag === state_tag) {
  //         return cinstrument;
  //       }
  //     }
  //   }
  //   return null;
  // }

  // private hasChildDoc(instrument: any, state_tag: string): boolean {
  //   return this.getChildDoc(instrument, state_tag) !== null;
  // }

  aiAnalysisAvailable(instrument: any): boolean {
    // return instrument.item.children && instrument.item.children.length > 0;
    return this.util.hasDoc(instrument.item, 'ANALYSED');
  }

  aiAnalysisPurchased(instrument: any): boolean {
    return instrument.item.is_analyzed === 1;
  }

  private docByVersion(instrument: any): string {
    if (this.aiAnalysisPurchased(instrument)) {
      const cinstrument = this.util.getDoc(instrument.item, 'ANALYSED');
      if (cinstrument) {
        return this.api.docHref(cinstrument);
      }
    }
    return this.api.docHref(instrument.item);
  }

  iframeSrc(instrument: any): string {
    return (instrument.has_been_selected) ? this.docByVersion(instrument) : '';
  }

  doSearch(e?: any) {
    if (e) { e.stopPropagation(); }
    const dialogRef = this.dialog.open(SearchInstrumentsComponent, {
      data: <SearchInstrumentsDialogData>{ title: 'SEARCH', searchterms: this.searchterms, cancel: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('search result', result);
      if (!result.cancel) {
        this.util.confirm({ message: 'Not yet implemented', title: 'SEARCH' });
      }
    });
  }

  doAiAnalysis(e: any, instrument: any) {
    if (e) { e.stopPropagation(); }
    this.util.confirm({
      message: 'Do you agree to a charge of $' + this.session.numberFormat(2, 2) + '?'
    }).subscribe(result => {
      if (result && !result.cancel) {
        this.api.getInstrument({
          uid: this.session.uid,
          id: this.parcel_id,
          iid: instrument.item.id,
          buyAnalysis: true,
          fake_login: true
        }).subscribe(response => {
          console.log('doAiAnalysis-response', response);
          if (response.status === 'OK') {
            instrument.item.is_analyzed = response.instruments[0].is_analyzed;
          } else {
            console.log('doAiAnalysis-instrument-response-error', response);
            this.util.alert({ message: response.messages.join() });
          }
        });
      }
    });
  }

  adClick(e: any, instrument: any, ad: any): void {
    if (e) { e.stopPropagation(); }
    window.open(ad.url, 'ca-ads-tab');
  }
}
