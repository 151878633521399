import { Component, OnInit, Input } from '@angular/core';
import { L } from 'leaflet';

// see https://stackoverflow.com/questions/39512421/angular-2-leaflet-maps
// see https://codehandbook.org/use-leaflet-in-angular/

// get access to globals
declare var $: any;
declare var L: any;

@Component({
  selector: 'app-lfmap-element',
  templateUrl: './lfmap-element.component.html',
  styleUrls: ['./lfmap-element.component.css']
})
export class LFMapElementComponent implements OnInit {

  @Input() height;
  @Input() id;

  private map;

  constructor() {
  }

  ngOnInit() {
    // let tag = "#" + this.id + " > div";
    const mapid = this.id + '-leaflet';
    $('#' + this.id + ' > div')
      .height(this.height)
      .prop('id', mapid);

    this.map = L.map(mapid);
    // L.tileLayer(
    // 	'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    // 		attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    // 	}
    // ).addTo(map);
    L.tileLayer(
      'https://api.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoidHdpbGtpbnNvbiIsImEiOiJYLUgycm5vIn0.Z7H4BQFXgQnNBEWwzdNoSA', {
      attribution: '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }
    ).addTo(this.map);

    this.map.setView([43.461891, -80.538694], 13);
  }

  // public setView(coords: Array<number>, zoom: number): void {
  // 	this.map.setView(coords, zoom);
  // }

}
