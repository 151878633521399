import {
  Component, OnInit, AfterViewInit, Injectable, OnDestroy, Input
} from '@angular/core';
import { ViewChild } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ApiServerService } from '../services/apiserver.service';
import { UserSessionService } from '../services/usersession.service';
// import { UtilService } from '../services/util.service';

import { TreeItemNode, CategoryTreeComponent } from '../category-tree/category-tree.component';

interface TreeDataNode {
  name: string;
  instruments: [];
  subtreeInstrumentCount: number;
  subtreeInstrumentPrice: number;
  children?: TreeDataNode[];
  checked: boolean;
}

interface TreeDatabaseInterface {
  dataChange: BehaviorSubject<TreeItemNode[]>;
  insertItem(parent: TreeItemNode, name: string): void;
  updateItem(node: TreeItemNode, name: string): void;
  itemToggle(node: TreeItemNode, descendants: TreeItemNode[], checked: boolean): void;
  // leafItemToggle(node: TreeItemNode, checked: boolean): void;
}

@Injectable()
export class TreeDatabase implements TreeDatabaseInterface {
  dataChange = new BehaviorSubject<TreeItemNode[]>([]);

  private get data(): TreeItemNode[] { return this.dataChange.value; }

  constructor(
    private category: any,
    private api: ApiServerService,
    private session: UserSessionService,
  ) {
    this.initialize(category.children);
  }

  initialize(items: TreeDataNode[]) {
    // Build the tree nodes from Json object. The result is a list of `TreeItemNode` with nested
    //     file node as children.

    const data = this.buildFileTree(items, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  buildFileTree(obj: TreeDataNode[], level: number): TreeItemNode[] {
    const accumulator: TreeItemNode[] = new Array();
    obj.forEach((item: TreeDataNode, index) => {
      if (item.subtreeInstrumentCount) {
        const node = new TreeItemNode();
        node.item = item.name + ' (' + item.subtreeInstrumentCount + ')';
        node.body = item.instruments;
        if (item.children && item.children.length) {
          node.children = this.buildFileTree(item.children, level + 1);
        }
        node.data = item;
        accumulator.push(node);
      }
    });
    return accumulator;
  }

  /** Add an item to to-do list */
  insertItem(parent: TreeItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as TreeItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TreeItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }

  itemToggle(node: TreeItemNode, descendants: TreeItemNode[], checked: boolean) {
    // console.log("itemToggle", node, checked);

    // TODO: make the interface between 'tree' and 'data' mode general
    node.data.checked = checked;
    descendants.forEach(function (item: TreeItemNode) {
      item.data.checked = checked;
      // console.log("itemToggle", item, item.data);
    });
  }

  // leafItemToggle(node: TreeItemNode, checked: boolean) {
  // 	console.log("leafItemToggle", node, checked);
  // 	node.data.checked = checked;
  // }
}

@Component({
  selector: 'app-instrument-category',
  templateUrl: './instrument-category.component.html',
  styleUrls: ['./instrument-category.component.css']
})
export class InstrumentCategoryComponent implements OnInit, AfterViewInit {

  @Input() category: any;

  @Input() treedata: TreeDatabase;

  @ViewChild(CategoryTreeComponent, { static: false }) categoryTree: CategoryTreeComponent;

  constructor(
    private api: ApiServerService,
    private session: UserSessionService,
    // private util: UtilService,
  ) {
  }

  ngOnInit() {
    this.treedata = new TreeDatabase(this.category, this.api, this.session);
  }

  ngAfterViewInit() {
  }

  isMyParcel(): boolean {
    return this.session.parcelIsMine;
  }

  selectAll(on: boolean): void {
    this.categoryTree.selectAll(null, on);
  }

  expandAll(on: boolean): void {
    this.categoryTree.expandAll(null, on);
  }

}
