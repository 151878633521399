import { Component, OnInit } from '@angular/core';
import { UserSessionService } from '../services/usersession.service';
import { ApiServerService } from '../services/apiserver.service';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { UtilService } from '../services/util.service';

import { MatDialog } from '@angular/material';

import { ParcelPickerComponent, ParcelPickerData } from '../parcel-picker/parcel-picker.component';

// import { MessageBusService } from '../services/messagebus.service';
// import { ConfirmMessage } from '../messagebus-messages/confirm-message.class';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  isLoaded = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public session: UserSessionService,
    private api: ApiServerService,
    // private messageBusService: MessageBusService,
    private dialog: MatDialog,
    private util: UtilService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // TODO: fetch parcels for uid
      console.log('PortfolioComponent', params.uid);
      this.api.getParcels({
        // uid: params.uid || this.session.uid,
        parcelsFromUID: true
      })
        .subscribe(response => {
          console.log('PortfolioComponent', response);
          if (response.status === 'OK') {
            this.session.portfolio = response.parcels;
            this.isLoaded = true;
          } else {
            // alert(response.messages.join());
            // this.messageBusService.send(new ConfirmMessage(response.messages.join()));
            this.util.alert({ message: response.messages.join() });
          }
        });
    });
  }

  portfolioCount(): number {
    return this.session.portfolio && this.session.portfolio.length ? this.session.portfolio.length : 0;
  }

  doParcel(e, parcel): void {
    e.stopPropagation();
    console.log('doParcel', parcel);
    this.router.navigate(['/parcel', parcel.id]);
  }

  doAddParcel(e): void {
    e.stopPropagation();
    const dialogRef = this.dialog.open(ParcelPickerComponent, {
      width: '80vw',
      // height: '60vh',
      data: <ParcelPickerData>{ type: 'parcel', cancel: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with result', result);
      if (result && !result.cancel) {
        console.log('select result', result);
        const id = result.result.GeoJSON.features[0].id;
        this.router.navigate(['/parcel', id]);
      }
    });
  }

  // doSelectArea(e): void {
  // 	e.stopPropagation();
  // 	const dialogRef = this.dialog.open(ParcelPickerComponent, {
  // 		width: '80vw',
  // 		// height: '60vh',
  // 		data: <ParcelPickerData>{ type: "area", cancel: false }
  // 	});

  // 	dialogRef.afterClosed().subscribe(result => {
  // 		console.log('The dialog was closed with result', result);
  // 		if (result && !result.cancel) {
  // 			console.log("search result", result);
  // 			this.session.setParcels(result.result);
  // 		}
  // 	});
  // }
}
