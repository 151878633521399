import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { ApiServerService } from '../services/apiserver.service';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';

import { UserSessionService } from '../services/usersession.service';
// import { InstrumentsComponent } from '../instruments/instruments.component';
import { UtilService } from '../services/util.service';

import { PageBaseComponent } from '../page-base/page-base.component';

@Component({
  selector: 'app-parcel-page',
  templateUrl: './parcel-page.component.html',
  styleUrls: ['./parcel-page.component.css']
})
export class ParcelPageComponent extends PageBaseComponent implements OnInit, AfterViewInit {

  isLoaded = false;

  // @ViewChild(InstrumentsComponent) instruments: InstrumentsComponent;

  constructor(
    protected session: UserSessionService,
    protected router: Router,
    private route: ActivatedRoute,
    private api: ApiServerService,
    public location: Location,
    private util: UtilService,
  ) {
    super(session, router);
  }

  ngOnInit() {
    super.ngOnInit();
    // this.session.clearParcels();
    this.isLoaded = false;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.session.isLoggedIn) {
      this.route.params.subscribe(params => {
        console.log('ParcelPageComponent', params.id);
        this.api.getParcels({
          // uid: this.session.uid,
          id: params.id,
          parcelFromID: true
        })
          .subscribe(response => {
            console.log('ParcelPageComponent-response', response);
            if (response.status = 'OK') {
              this.session.setParcels(response.parcel);
              this.isLoaded = true;
            } else {
              console.log('ParcelPageComponent-response-error', response);
              this.util.alert({ message: response.messages.join() });
            }
          });
      });
    } else {
      this.router.navigate(['/home']);
    }

  }
}
