import { Component, OnInit, AfterViewInit } from '@angular/core';
// import { Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit, AfterViewInit {

  // llocation: any;
  path: string;

  constructor(
    // public location: Location,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // this.llocation = location;
    this.route.data.pipe(take(1))
      .subscribe((data: { path: string }) => {
        this.path = data.path;
      });
  }

  ngAfterViewInit() {
  }

}
