import { Component, Inject, OnInit, Input, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';

import { UserSessionService } from '../services/usersession.service';

import { ParcelPickerComponent, ParcelPickerData } from '../parcel-picker/parcel-picker.component';

import { PageBaseComponent } from '../page-base/page-base.component';

// get access to globals
declare let $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent extends PageBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    protected session: UserSessionService,
    protected router: Router,
    private route: ActivatedRoute,
  ) {
    super(session, router);
  }

  ngOnInit() {
    super.ngOnInit();
    this.route.params.subscribe(params => {
      console.log('HomePageComponent', params.uid);
    });
  }

  public doSuppressClick(e) {
    e.stopPropagation();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }
}
