export class InstrumentPickedMessage {

  public instrument: object = null;
  public state_tag: string = null;

  constructor(instrument: object, state_tag: string) {
    this.instrument = instrument;
    this.state_tag = state_tag;
  }

}
