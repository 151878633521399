import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { UserSessionService } from '../services/usersession.service';
import { UtilService } from '../services/util.service';

// get access to globals
declare let $: any;

export interface ParcelPickerData {

  result: any;
  type: string;	// 'select' or 'search'
  cancel: boolean;
}

@Component({
  selector: 'app-parcel-picker',
  templateUrl: './parcel-picker.component.html',
  styleUrls: ['./parcel-picker.component.css']
})
export class ParcelPickerComponent implements OnInit, AfterViewInit {

  // hide = true;

  constructor(
    public session: UserSessionService,
    private util: UtilService,
    public dialogRef: MatDialogRef<ParcelPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ParcelPickerData,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('app-parcel-picker').closest('.cdk-overlay-container').addClass('parcel-picker-dialog-container');
  }

  onNoClick(): void {
    this.data.cancel = true;
    this.dialogRef.close(this.data);
  }

  pickParcel(): boolean {
    return this.data.type === 'parcel';
  }

  onParcelPicked(payload): any {
    console.log('parcel picked', payload);
    this.data.result = payload;
    if (!payload.GeoJSON.features.length) {
      this.util.alert({ message: 'Parcel not found in database.' });
    }
  }

  pickArea(): boolean {
    return this.data.type === 'area';
  }

  onAreaPicked(payload): any {
    console.log('area picked', payload);
    this.data.result = payload;
  }
}
