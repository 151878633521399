import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

//get access to globals
declare let $: any;

// see http://jasonwatmore.com/post/2018/06/25/angular-6-communicating-between-components-with-observable-subject

@Injectable({ providedIn: 'root' })
export class MessageBusService {

    private subject = new Subject<object>();

    send(message: object) {
        this.subject.next(message);
    }

    // clearMessage() {
    //     this.subject.next();
    // }

    recv(types: Array<any>): Observable<object> {
        // console.log("recv", types);
        return this.subject.asObservable().pipe(
            filter((message: object) => {
                // console.log("filtering " + message.constructor.name + ", " + JSON.stringify(types) + " " + $.inArray(message.constructor.name, types));
                return 0 <= $.inArray(message.constructor.name, types);
            })
        );
    }
}    